import React from "react";

const ErrorMessage = () => {
  return (
    <div className="w-full rounded-lg text-gray-900 bg-red-400 mx-auto px-4 py-2 max-w-md">
      <p>Couldn't Fetch News At these moment</p>
    </div>
  );
};

export default ErrorMessage;
